import moment from 'moment';
import 'moment-timezone';
import Multiselect from 'vue-multiselect';
import Topbar from '@/components/topbar/topbar_component.vue';
import Navbar from '@/components/navbar/navbar_component.vue';
import Loader from '@/components/Loaders/loader_component.vue';
import Popover from '@/components/popovers/popover_component.vue';
import InfiniteLoading from 'vue-infinite-loading';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default{
    name : 'workbench',
    components:{
        Topbar,
        Navbar,
        Multiselect,
        Loader,
        Popover,
        InfiniteLoading,
        DateRangePicker
    },
    data(){
        return{ 
            open_popup_type : '',
            edit_meeting_obj: {},
            start: 1,
            group_name : JSON.parse(sessionStorage.getItem('organization_details')).organisation_name,
            url_path : this.$route.path,
            title : '',
            total_minutes_obj : 0,
            search_text:'',
            minute_order : -1,
            meeting_order: -1,
            due_date_order :-1,
            start_date_order : -1,
            selected_card_index: -1,
            not_exist_minute_data : true,
            assigne_filter_dropdown : false,
            status_filter_dropdown : false,
            type_filter_dropdown : false,
            priority_filter_dropdown : false,
            meetname_filter_dropdown : false,
            group_filter_dropdown    :false,
            due_date_dropdown: false,
            start_date_dropdown: false,
            reset_page:false,
            meeting_popup: false,
            button_hide : false,
            status_obj : this.Constants.MIN_STATUS_TYPE,
            priority_obj : [this.Constants.SELECT_ALL,this.Constants.PRIORITY.TYPE_1,this.Constants.PRIORITY.TYPE_2,this.Constants.PRIORITY.TYPE_3,this.Constants.PRIORITY.TYPE_4],
            type_obj :[this.Constants.SELECT_ALL,this.Constants.TASK_TYPE.TYPE_1,this.Constants.TASK_TYPE.TYPE_2,this.Constants.TASK_TYPE.TYPE_3,this.Constants.TASK_TYPE.TYPE_4,this.Constants.TASK_TYPE.TYPE_6],
            status_selected : [],
            org_members : [],
            members_selected : [],
            priority_selected : [],
            type_selected : [],
            all_meetings : [],
            all_groups:    [],
            meetings_selected : [],
            group_selected    :[],
            meetings_list:[],
            all_filters:[],
            all_favourite_cards:[],
            dueDateRange   : {"startDate":moment().add('-30','days').format("MMM D, YYYY"),"endDate":moment().add('10','days').format("MMM D, YYYY")},
            startDateRange : {"startDate":moment().add('-30','days').format("MMM D, YYYY"),"endDate":moment().add('10','days').format("MMM D, YYYY")},
            start_date_selected : false,
            due_date_selected :false,
            filter_value: {},
            card_select : false  
        }
    },

    async created(){
        this.title = this.$route.path == '/workbench' ? 'Workbench' :  'My Meetings';
        if(this.$store.state.to_path != "/instantmeeting" ) {
            if(sessionStorage.getItem('agenda_details')){
                let meeting_details = JSON.parse(sessionStorage.getItem('meet_details'))
                let meeting_obj = {
                    "id": meeting_details.meeting._id,
                    "status": 'Draft',
                    "local_timezone": this.Constants.MEET_TIME_ZONE,
                    "meeting_end_time" : moment().format("YYYY-MM-DD HH:mm")
                }
    
                this.$store.dispatch('CrudMeeting',[this.$store.state.api, meeting_obj]);
                sessionStorage.removeItem('meet_details');
                sessionStorage.removeItem('agenda_details');
                sessionStorage.removeItem('selected_members');
                sessionStorage.removeItem('all_participates');
                sessionStorage.removeItem('members_attendance');
            }
        }
        await this.get_all_meetings();  
        await this.get_organisation_members();
        await this.getFavouriteFilterCards();
        await this.getFilters();
        if(localStorage.getItem('filter_details') ){     
            this.filter_value = JSON.parse(localStorage.getItem('filter_details'));
            let fav_index =  this.all_favourite_cards.findIndex(item => item._id == this.filter_value._id);
            if(fav_index > -1){
                this.selected_card_index = fav_index;
            }
        }  
    },

    async mounted(){
        document.addEventListener("scroll", this.handleScroll);
        if(this.title == 'Workbench'){
            document.addEventListener('click', this.documentClick);
            let index = this.status_obj.indexOf(this.Constants.SELECT_ALL);
            if(index == -1){
                this.status_obj.splice(0, 0, this.Constants.SELECT_ALL);
            }        
        }
    },
    beforeDestroy() {
        document.removeEventListener("scroll", this.handleScroll);
        document.removeEventListener('click', this.documentClick);
    },
     
    watch: {
        $route (to) {
            this.url_path = to.path;
            this.title = this.url_path == "/workbench" ? "Workbench": "My Meetings";
            this.reset_infinite_state();
        },

        minute_order(){
            this.button_hide = true;
            this.card_select = false;
            this.reset_infinite_state();
        },

        meeting_order(){
            this.button_hide = true;
            this.card_select = false;
            this.reset_infinite_state();
        },

        start_date_order(){
            this.button_hide = true;
            this.card_select = false;
            this.reset_infinite_state(); 
        },

        due_date_order(){
            this.button_hide = true;
            this.card_select = false;
            this.reset_infinite_state(); 
        }
    },

    methods:{
        
        toast(variant, title, message, append = false) {
            this.counter++
            this.$bvToast.toast(message, {
              title: title,
              solid: true,
              variant : variant,
              appendToast: append
            })
        },
        
        async infiniteHandler($state){
            this.$store.state.loader_popup = true;
            this.state = $state;
            if (this.reset_page) {
                this.meetings_list = [];
                this.start = 1;
                this.reset_page = false;
                this.$store.state.loader_popup = false;
                return this.$refs.infiniteLoading.stateChanger.reset();
            }
            if(this.title == 'Workbench'){
                let  pagination_obj = {
                    "organiser_id" : this.$store.state.organiser_id,
                    "page":this.start,
                    "pagination":this.Constants.INFINITE_SCROLL.WORKBENCH_PAGINATION,
                    "filters":{
                        "meeting_id_order"   : this.meeting_order,
                        "minute_id_order"    : this.minute_order,
                        "meeting_date_order" : this.start_date_order,
                        "due_date_order"     : this.due_date_order
                    }
                }

                if(this.search_text || ((localStorage.getItem('filter_details') ? true:false) ? (this.button_hide ? (this.card_select ? false:true):false):true)){


                    if(this.group_selected.length != 0){
                        let all_index = this.group_selected.findIndex(item => item == 0);
                        let group_select = [...this.group_selected]
                        if(all_index > -1){
                            group_select.splice(all_index,1);
                        }
                        Object.assign(pagination_obj.filters, {"group_id": group_select});
                    }
        
                    if(this.meetings_selected.length != 0){
                        let all_index = this.meetings_selected.findIndex(item => item == 0);
                        let meet_select = [...this.meetings_selected]
                        if(all_index > -1){
                            meet_select.splice(all_index,1);
                        }
                        Object.assign(pagination_obj.filters, {"meeting_id":meet_select});
                    }
                    
                    if(this.type_selected.length != 0 && this.type_selected.length != this.type_obj.length){
                        Object.assign(pagination_obj.filters, {"type": this.type_selected});
                    }

                    Object.assign(pagination_obj.filters, {"start_date": {'from': moment(this.startDateRange.startDate).format('YYYY-MM-DD'), 'to' : moment(this.startDateRange.endDate).format('YYYY-MM-DD')}});

                    if(this.members_selected.length != 0 && this.members_selected.length != this.org_members.length){
                        Object.assign(pagination_obj.filters, {"asignee_id": this.members_selected});
                    }
    
                    if(this.priority_selected.length != 0 && this.priority_selected.length != this.priority_obj.length && this.type_selected.includes(this.Constants.TASK_TYPE.TYPE_4)){
                        Object.assign(pagination_obj.filters, {"priority":this.priority_selected });
                    }

                    Object.assign(pagination_obj.filters, {"due_date": {'from': moment(this.dueDateRange.startDate).format('YYYY-MM-DD'), 'to' : moment(this.dueDateRange.endDate).format('YYYY-MM-DD')}});
                
                    if(this.status_selected.length != 0 && this.status_selected.length != this.status_obj.length){
                        Object.assign(pagination_obj.filters, {"status": this.status_selected});
                    }
    
                    if(this.search_text != ''){
                        Object.assign(pagination_obj, {"search": this.search_text});
                    }
    
                }else{

                    let session_filter = JSON.parse(localStorage.getItem('filter_details')).filter_parameters;

                    if(session_filter.meeting_id_order){
                        this.meeting_order = session_filter.meeting_id_order;
                    }else{
                        this.meeting_order = -1;
                    }

                    if(session_filter.minute_id_order){
                        this.start_date_order = session_filter.minute_id_order;
                    }else{
                        this.start_date_order = -1;
                    }

                    if(session_filter.due_date_order){
                        this.due_date_order = session_filter.due_date_order;
                    }else{
                        this.due_date_order = -1;
                    }

                    if(session_filter.meeting_date_order){
                        this.minute_order = session_filter.meeting_date_order;
                    }else{
                        this.minute_order = -1;
                    }

                    if(session_filter.group_id){
                        this.group_selected = session_filter.group_id;
                    }else{
                        this.group_selected = [];
                    }

                    if(session_filter.meeting_id){
                        this.meetings_selected = session_filter.meeting_id;
                    }else{
                        this.meetings_selected = []; 
                    } 
 
                    if(session_filter.type){
                        this.type_selected = session_filter.type;
                    }else{
                        this.type_selected = [];
                    }

                    if(session_filter.start_date){
                        this.startDateRange.startDate = moment(session_filter.start_date.from).format('MMM D, YYYY');
                        this.startDateRange.endDate = moment(session_filter.start_date.to).format('MMM D, YYYY');    
                    }else{
                        this.startDateRange.startDate = moment().add('-30','days').format("MMM D, YYYY");
                        this.startDateRange.endDate   = moment().add('10','days').format("MMM D, YYYY");
                    }

                    if(session_filter.asignee_id){
                        this.members_selected = session_filter.asignee_id;
                    }else{
                        this.members_selected = [];
                    }
 
                    if(session_filter.priority){
                        this.priority_selected = session_filter.priority;
                    }else{
                        this.priority_selected  = [];
                    }
      
                    if(session_filter.due_date){
                        this.dueDateRange.startDate = moment(session_filter.due_date.from).format('MMM D, YYYY');
                        this.dueDateRange.endDate = moment(session_filter.due_date.to).format('MMM D, YYYY');    
                    }else{
                        this.dueDateRange.startDate =  moment().add('-30','days').format("MMM D, YYYY");
                        this.dueDateRange.endDate   =  moment().add('10','days').format("MMM D, YYYY"); 
                    }

    
                    if(session_filter.status){
                        this.status_selected = session_filter.status;
                    }else{
                        this.status_selected = [];
                    }
    
                    pagination_obj.filters = session_filter;
                }  
                await this.$store.dispatch('getAllMinutesRecords',[this.$store.state.api,pagination_obj]);

                if(this.group_selected.length == this.all_groups.length-1){
                    this.group_selected.push(0);
                }

                if(this.meetings_selected.length == this.all_meetings.length-1){
                    this.meetings_selected.push(0);
                }

            }else{
                let  pagination_obj = {
                    "organiser_id" : this.$store.state.organiser_id,
                    "page":this.start,
                    "pagination":this.Constants.INFINITE_SCROLL.WORKBENCH_PAGINATION
                }
                await this.$store.dispatch('getAllMeetingsRecords',[this.$store.state.api,pagination_obj]);
                
            }

            this.$store.state.loader_popup = false;  
            this.get_records($state); 

        },

        async get_organisation_members(){
            this.org_members = await this.$store.dispatch('getOrgMembers',[this.$store.state.api,this.$store.state.org_id]);

            this.org_members.splice(0, 0, {'_id':0,'full_name': this.Constants.SELECT_ALL});
        },

        async get_all_meetings(){

            await this.$store.dispatch('getAllMeetingsAndGroups',[this.$store.state.api,{"organiser_id" : this.$store.state.organiser_id, "org_id" : this.$store.state.org_id}]);

            this.all_meetings = this.$store.state.all_meetings_and_records.meetings;

            this.all_meetings.splice(0, 0, {'_id':0,'meeting_title': this.Constants.SELECT_ALL});

            this.all_groups = this.$store.state.all_meetings_and_records.groups;

            this.all_groups.splice(0, 0, {'_id':0,'group_name': this.Constants.SELECT_ALL});

           
        },
 
        async goInstantMeeting(){
            if(!sessionStorage.getItem('meet_details')){
                let instant_details = {
                    "org_id": this.$store.state.org_id,
                    "organiser_id": this.$store.state.organiser_id,
                    "meeting_start_time": moment().utc().tz(this.Constants.MEET_TIME_ZONE).format('YYYY-MM-DD HH:mm'),
                    "meeting_timezone": this.Constants.MEET_TIME_ZONE,
                    "local_timezone":this.Constants.MEET_TIME_ZONE
                }

                await this.$store.dispatch('CrudMeeting',[this.$store.state.api, instant_details]);
                sessionStorage.setItem('meet_details', JSON.stringify(this.$store.state.meeting_details));

                this.$store.state.meeting_details.participants.full_name = JSON.parse(sessionStorage.getItem('organization_details')).full_name;
                this.$store.state.meeting_details.participants.html_name = "<span>"+JSON.parse(sessionStorage.getItem('organization_details')).full_name+"&nbsp;</span><br>"+"<small class='grey'>"+JSON.parse(sessionStorage.getItem('organization_details')).email+"</small>"
                this.$store.state.meeting_details.participants.email = JSON.parse(sessionStorage.getItem('organization_details')).email;
                sessionStorage.setItem('all_participates', JSON.stringify([this.$store.state.meeting_details.participants]));
                this.$store.commit('updateAttendance',{"participant_id": this.$store.state.meeting_details.participants._id,"attendance_status": true});
                sessionStorage.setItem('members_attendance', JSON.stringify([this.$store.state.meeting_details.participants._id]));
                this.$router.push('instantmeeting');
            }
        },

        scrollToTop(){
            // Scroll to top logic
            document.documentElement.scrollTo({
              top: 0,
              behavior: "smooth"
            })
        },

        get_records($state){

            let meetings_minutes_obj =  '' ;
            let meetings_minutes_obj_length = 0;
            
            if(this.title == 'Workbench'){
                let total_records =  this.$store.state.minutes_records_obj.total; 
                this.total_minutes_obj      += this.$store.state.minutes_records_obj.result.length;
                meetings_minutes_obj_length =  total_records > this.total_minutes_obj;
                meetings_minutes_obj        =  this.$store.state.minutes_records_obj.result

            }else{
                this.total_minutes_obj = 0;
                meetings_minutes_obj_length  =    this.$store.state.meetings_records_obj.length;
                meetings_minutes_obj         =    this.$store.state.meetings_records_obj
            }

            if(meetings_minutes_obj_length) {
                this.start ++;  
                this.meetings_list.push(...meetings_minutes_obj);
                if($state){
                    $state.loaded()
                }else {
                    $state.complete();
                }
            } else {
                this.meetings_list.push(...meetings_minutes_obj);
                $state.complete();
            }
        },

        async status_change(parameters,meeting_id,minute_id){
            let minute_obj = {
                    "meeting_id": meeting_id,
                    "minutes":{
                        "operation_type" : 2,
                        "id" : minute_id,
                        "parameters" : parameters
                    }
                }

            await this.$store.dispatch('saveAgendaMeeting',[this.$store.state.api,minute_obj]);
            this.toast('info','Success','Updated status successfully');
        },

        view_meeting(meeting_id,status){
            switch(status){
                case 'Draft' :
                    this.$router.push('draftminutes/'+meeting_id);
                    break;
                case 'Published':
                    this.$router.push('viewminutes/'+meeting_id);
                    break;
            }
        },

        async getFilters(){
            await this.$store.dispatch('getFilters',[this.$store.state.api,{ "organiser_id": this.$store.state.organiser_id }]);
            this.all_filters = this.$store.state.get_filter_obj;
        },

        async getFavouriteFilterCards(){
            await this.$store.dispatch('getFavouriteCards',[this.$store.state.api,{ "organiser_id": this.$store.state.organiser_id }]);
            this.all_favourite_cards = this.$store.state.favourite_filter_obj;
        },

        open_delete_popup(meeting_id,minute_id='',meeting_type,index){
            if(minute_id !=''){
                this.open_popup_type = "delete_action_popup";
                this.edit_meeting_obj.minute_id   =  minute_id;
                this.edit_meeting_obj.meeting_type      =  meeting_type
            }else{
                this.open_popup_type = "delete_meeting_popup";
                this.edit_meeting_obj.minute_id   = '';
            }
            this.edit_meeting_obj.minutes_index  = index;
            this.meeting_popup = true;
            this.edit_meeting_obj.meeting_id  = meeting_id;
        },

        confirm_popup_function(type,popup_content){
            switch(type){
                case 'delete_meeting':
                    this.delete_minute_meeting();
                    break;
                case 'popup_content':
                    this.update_comment(popup_content);
                    break;
                case 'save_view':
                    this.save_new_view(popup_content)  
            }
        },

        async delete_minute_meeting(){
            if(this.edit_meeting_obj.minute_id == ''){
                this.$store.commit("deleteMeeting",{"meeting_id":this.edit_meeting_obj.meeting_id});
            }else{
                let agendaObj = {
                    "meeting_id" : this.edit_meeting_obj.meeting_id,
                        "minutes":{
                            "id"            : this.edit_meeting_obj.minute_id,
                            "operation_type": 3
                        }
                    }

                await this.$store.dispatch('saveAgendaMeeting',[this.$store.state.api,agendaObj]);
            }

            this.meeting_popup = false;
            this.meetings_list.splice(this.edit_meeting_obj.minutes_index,1);
            if(this.edit_meeting_obj.minute_id == ''){
                this.toast('info','Success','Deleted meeting successfully');
            }else{
                this.toast('info','Success','Deleted minute successfully');
            }      
        },

        save_filter_popup(){
            this.open_popup_type = "save_new_filter_popup";
            this.meeting_popup = true;
        },

        open_comments_popup(meeting_id,minute_id,minute_type,comments,index){  
            let meeting_obj = {
                "meeting_id"       : meeting_id,
                "minute_id"        : minute_id,
                "meeting_type"     : minute_type,
                "comment"          : comments,
                "minutes_index"    : index
            }
            this.edit_meeting_obj   =  meeting_obj;
            this.open_popup_type = "meeting_add_edit_popup";
            this.meeting_popup = true;
        },

        remove_meeting_pop_close(){
            this.meeting_popup =  false;
        },

        async update_comment(comment_content){ 
            if(this.edit_meeting_obj.minute_id == ''){
                let instant_details = {
                    "id"      : this.edit_meeting_obj.meeting_id,
                    "comments": comment_content
                }
                await this.$store.dispatch('CrudMeeting',[this.$store.state.api, instant_details]);
            }else{
                let  minute_obj = {
                    "meeting_id": this.edit_meeting_obj.meeting_id,
                    "minutes":{
                        "operation_type" : 2,
                        "id"             : this.edit_meeting_obj.minute_id,
                        "minute_type"    : this.edit_minute_type,
                        "comments"       : comment_content
                    }    
                }
                await this.$store.dispatch('saveAgendaMeeting',[this.$store.state.api,minute_obj]);         
            }
            this.meeting_popup = false;
            this.$nextTick(() => {
                this.meetings_list[this.edit_meeting_obj.minutes_index].comments = comment_content;
            }) 

            if(this.edit_meeting_obj.comment){
                this.toast('info','Success','Updated comment successfully'); 
            }else{
                this.toast('info','Success','Added comment successfully'); 
            }   
        },

        async toggle_fav_icon(filter_id,is_favourite){
            this.card_select = true;
            this.$store.state.loader_popup = true;
            let view_details_obj = {
                "id": filter_id,
                "is_favourite":!is_favourite
            }
            await this.$store.dispatch('saveFilter',[this.$store.state.api, view_details_obj]);

            let index = this.all_filters.findIndex(item => item._id == filter_id);

            await this.getFavouriteFilterCards();

            this.all_filters[index].is_favourite = this.$store.state.save_filter_obj.is_favourite;

            if(localStorage.getItem('filter_details') ){     
                let filter_session = JSON.parse(localStorage.getItem('filter_details'));
                let fav_index =  this.all_favourite_cards.findIndex(item => item._id == filter_session._id);
                if(fav_index > -1){
                    this.selected_card_index = fav_index;
                }else{
                    this.selected_card_index = -1; 
                }
            } 
            
            this.$store.state.loader_popup = false;

        },

        delete_filter_view(filter_id){ 
            this.$store.commit("deleteFilter",{"filter_id":filter_id});
             
            let index = this.all_filters.findIndex(item => item._id == filter_id);

            if(this.filter_value._id  == filter_id){
                this.remove_session_data();
            }
            
            if (index > -1) {
                this.$store.state.get_filter_obj.splice(index,1);
            }

            let fav_index = this.all_favourite_cards.findIndex(item => item._id == filter_id)

            if (fav_index > -1) {
                this.$store.state.favourite_filter_obj.splice(fav_index,1);
            }

            this.toast('info','Success','Deleted view successfully');
        },

        async favourite_card_selection(filter_id,index){

            this.card_select = true;

            var fav_class_index =  document.querySelector('.favourite_cards_'+index);

            if(fav_class_index.classList.contains('btn-light-blue-black')){
                this.remove_session_data();
            }else{
                this.selected_card_index =  index;
                this.filter_value =  this.all_filters.find(item => item._id == filter_id); 
                await this.filter_change(filter_id); 
            }
   
        },

        async filter_select(filter_id){

            let fav_index =  this.all_favourite_cards.findIndex(item => item._id == filter_id);

            if(fav_index > -1){
             this.selected_card_index = fav_index; 
            }else{
                this.selected_card_index = -1;  
            }

            await this.filter_change(filter_id)
        },

        async filter_change(filter_id){

            this.card_select = true;

            this.$store.state.loader_popup = true;

            await this.$store.dispatch('getFiltersDetails',[this.$store.state.api,{ "filter_id": filter_id}]);

            let result_array = this.$store.state.get_filter_details_obj;

            localStorage.setItem('filter_details', JSON.stringify(result_array));

            this.$store.state.loader_popup = false;

            this.reset_infinite_state();
        },


        async save_new_view(view_name,type=''){ 

            document.getElementsByClassName("update_button")[0].disabled = "true";

            let view_details_obj = {
                "filter_name": view_name ?  view_name : this.filter_value.filter_name,
                "filter_parameters" :{
                }   
            }

            if(this.filter_value._id && type){
                Object.assign(view_details_obj, {"id": this.filter_value._id}); 
            }else{
                Object.assign(view_details_obj, {"organiser_id":  this.$store.state.organiser_id});
            }

            if(this.meeting_order == 1){
                Object.assign(view_details_obj.filter_parameters,{"meeting_id_order": 1 });
            }

            if(this.minute_order == 1){
                Object.assign(view_details_obj.filter_parameters,{"minute_id_order": 1 });
            }

            if(this.start_date_order == 1){
                Object.assign(view_details_obj.filter_parameters,{"meeting_date_order": 1 });
            }

            if(this.due_date_order == 1){
                Object.assign(view_details_obj.filter_parameters,{"due_date_order": 1 });
            }

            if(this.group_selected.length != 0){
                let all_index = this.group_selected.findIndex(item => item == 0);
                let group_select = [...this.group_selected]
                if(all_index > -1){
                    group_select.splice(all_index,1);
                }
                Object.assign(view_details_obj.filter_parameters, {"group_id": group_select});
            }

            if(this.meetings_selected.length != 0){
                let all_index = this.meetings_selected.findIndex(item => item == 0);
                let meet_select = [...this.meetings_selected]
                if(all_index > -1){
                    meet_select.splice(all_index,1);
                }
                Object.assign(view_details_obj.filter_parameters, {"meeting_id": meet_select});
            }

            if(this.type_selected.length != 0 && this.type_selected.length != this.type_obj.length){
                Object.assign(view_details_obj.filter_parameters,{"type": this.type_selected});
            }

            Object.assign(view_details_obj.filter_parameters,{"start_date":{"from": moment(this.startDateRange.startDate).format('YYYY-MM-DD'),"to":moment(this.startDateRange.endDate).format('YYYY-MM-DD')}});

            if(this.members_selected.length){
                Object.assign(view_details_obj.filter_parameters, {"asignee_id": this.members_selected});
            }

            if(this.priority_selected.length != 0 && this.priority_selected.length != this.priority_obj.length && this.type_selected.includes(this.Constants.TASK_TYPE.TYPE_4)){
                Object.assign(view_details_obj.filter_parameters, {"priority":this.priority_selected});
            }

            Object.assign(view_details_obj.filter_parameters,{"due_date":{"from": moment(this.dueDateRange.startDate).format('YYYY-MM-DD'),"to":moment(this.dueDateRange.endDate).format('YYYY-MM-DD')}});


            if(this.status_selected.length != 0 && this.status_selected.length != this.status_obj.length){
                Object.assign(view_details_obj.filter_parameters, {"status": this.status_selected});
            }

            await this.$store.dispatch('saveFilter',[this.$store.state.api, view_details_obj]);

            if(this.filter_value._id && type){
               await this.filter_change(this.filter_value._id);
               await this.getFavouriteFilterCards();
               this.toast('info','Success','Updated view successfully');
            }else{
                await this.getFilters();
                this.toast('info','Success','Added view successfully');
            }

            document.getElementsByClassName("update_button")[0].disabled = "false";

            this.meeting_popup = false;

            this.button_hide = false;
        },

        async download(meeting_id) {
            let result = await this.$store.dispatch('getMeetingMinutesDownload', [this.$store.state.api, {"meeting_id": meeting_id, "local_timezone": this.Constants.MEET_TIME_ZONE}]);
            if (result.message.includes('success')) {
                window.location.href = this.$store.state.api + 'stylesheets/meeting_' + meeting_id + '.docx';
            }
        },

        remove_session_data(){
            this.filter_value = {};
            this.selected_card_index = -1;
            localStorage.removeItem('filter_details');
            this.minute_order = -1;
            this.meeting_order = -1;
            this.start_date_order = -1;
            this.due_date_order = -1;
            this.group_selected = [];
            this.members_selected =[];
            this.meetings_selected = [];
            this.type_selected =[];
            this. dueDateRange = {"startDate":moment().add('-30','days').format("MMM D, YYYY"),"endDate":moment().add('10','days').format("MMM D, YYYY")};
            this. startDateRange = {"startDate":moment().add('-30','days').format("MMM D, YYYY"),"endDate":moment().add('10','days').format("MMM D, YYYY")};
            this.priority_selected = [];
            this.status_selected = [];
            this.reset_infinite_state();
            this.$forceUpdate();
        },

        handleScroll(){
            var scroll_to_top = document.querySelector(".scroll_to_top");
            var rootElement = document.documentElement;
            var scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
            if ((rootElement.scrollTop / scrollTotal ) > 0.8) {
                scroll_to_top.classList.add("scroll_top_img")
            } else {
                scroll_to_top.classList.remove("scroll_top_img")
            }

        },

        documentClick(event){

            if(document.getElementById('assigne_head')){
                var isClickInsideAssignElement =  document.getElementById('assigne_head').contains(event.target);
            }
            if(document.getElementById('meeting_head')){
                var isClickInsideMeetElement =  document.getElementById('meeting_head').contains(event.target);
            }
            if(document.getElementById('type_head')){
                var isClickInsideTypeElement =  document.getElementById('type_head').contains(event.target);
            }
            if( document.getElementById('priority_head')){
                var isClickInsidePriorityElement =  document.getElementById('priority_head').contains(event.target);
            }
            if( document.getElementById('status_head')){
                var isClickInsideStatusElement =  document.getElementById('status_head').contains(event.target);
            }
            if( document.getElementById('due_date_head')){
                var isClickInsideDueDateElement =  document.getElementById('due_date_head').contains(event.target);
            }
            if( document.getElementById('start_date_head')){
                var isClickInsideStartDateElement =  document.getElementById('start_date_head').contains(event.target);
            }
            if( document.getElementById('group_head')){
                var isClickInsideGroupElement =  document.getElementById('group_head').contains(event.target);
            }
           
    
            if(!isClickInsideAssignElement && this.assigne_filter_dropdown){
                this.assigne_filter_dropdown = false;
            }else if(!isClickInsideMeetElement && this.meetname_filter_dropdown){
                this.meetname_filter_dropdown = false;
            }else if(!isClickInsideTypeElement && this.type_filter_dropdown){
                this.type_filter_dropdown = false;
            }else if(!isClickInsidePriorityElement && this.priority_filter_dropdown){
                this.priority_filter_dropdown = false;
            }else if(!isClickInsideStatusElement && this.status_filter_dropdown){
                this.status_filter_dropdown = false
            }else if(!isClickInsideDueDateElement && this.due_date_dropdown){
                this.due_date_dropdown = false
            }else if(!isClickInsideStartDateElement && this.start_date_dropdown){
                this.start_date_dropdown = false
            }else if(!isClickInsideGroupElement && this.group_filter_dropdown){
                this.group_filter_dropdown = false;
            }
        },

        select_assigne(e) {
            this.button_hide= true;
            this.card_select = false;
            if(e.target.value == 0 && e.target.checked){
                this.members_selected = [];
                this.org_members.forEach(member => {
                    this.members_selected.push(member._id);
                })
            }else if(e.target.value == 0 && !e.target.checked){
                this.members_selected = [];
            }else if(this.members_selected.length != this.org_members.length){
                let all_index = this.members_selected.findIndex(item => item == 0);
                if(all_index > -1){
                    this.members_selected.splice(all_index,1);
                }else if(this.members_selected.length == this.org_members.length-1){
                    this.members_selected.push(0);
                }
            }

            this.reset_infinite_state();
        },

        select_meeting(e){
            this.button_hide= true;
            this.card_select = false;
            if(e.target.value == 0 && e.target.checked){
                this.meetings_selected = [];
                this.all_meetings.forEach(member => {
                    this.meetings_selected.push(member._id);
                })
            }else if(e.target.value == 0 && !e.target.checked){
                this.meetings_selected = [];
            }else if(this.meetings_selected.length != this.all_meetings.length){
                let all_index = this.meetings_selected.findIndex(item => item == 0);
                if(all_index > -1){
                    this.meetings_selected.splice(all_index,1);
                }else if(this.meetings_selected.length == this.all_meetings.length-1){
                    this.meetings_selected.push(0);
                }
            }
            this.reset_infinite_state();
            
        },

        select_group(e){
            this.button_hide= true;
            this.card_select = false;
            if(e.target.value == 0 && e.target.checked){
                this.group_selected = [];
                this.all_groups.forEach(member => {
                    this.group_selected.push(member._id);
                })
            }else if(e.target.value == 0 && !e.target.checked){
                this.group_selected = [];
            }else if(this.group_selected.length != this.all_groups.length){
                let all_index = this.group_selected.findIndex(item => item == 0);
                if(all_index > -1){
                    this.group_selected.splice(all_index,1);
                }else if(this.group_selected.length == this.all_groups.length-1){
                    this.group_selected.push(0);
                }
            }

            this.reset_infinite_state();
        },
        
        select_type(e){
            this.button_hide= true;
            this.card_select = false;
            if(e.target.value == this.Constants.SELECT_ALL && e.target.checked){
                this.type_selected = this.type_obj;
            }else if(e.target.value == this.Constants.SELECT_ALL && !e.target.checked){
                this.type_selected = [];
            }else if(e.target.checked && this.type_selected.length == this.type_obj.length-1){
                let index = this.type_selected.indexOf(this.Constants.SELECT_ALL);
                if(index == -1){
                    this.type_selected.push(this.Constants.SELECT_ALL);
                }
            }else if(this.type_selected.length != this.type_obj.length){
                let all_index = this.type_selected.findIndex(item => item == this.Constants.SELECT_ALL);
                if(all_index > -1){
                    this.type_selected.splice(all_index,1);
                }else if(this.type_selected.length == this.type_obj.length-1){
                    this.type_selected.push(this.Constants.SELECT_ALL);
                }
            }
            this.reset_infinite_state();
        },

        select_priority(e){
            this.button_hide= true;
            this.card_select = false;
            if(e.target.value == this.Constants.SELECT_ALL && e.target.checked){
                this.priority_selected = this.priority_obj;
            }else if(e.target.value == this.Constants.SELECT_ALL && !e.target.checked){
                this.priority_selected = [];
            }else if(e.target.checked && this.priority_selected.length == this.priority_obj.length-1){
                let index = this.priority_selected.indexOf(this.Constants.SELECT_ALL);
                if(index == -1){
                    this.priority_selected.push(this.Constants.SELECT_ALL);
                }
            }else if(this.priority_selected.length != this.priority_obj.length){
                let all_index = this.priority_selected.findIndex(item => item == this.Constants.SELECT_ALL);
                if(all_index > -1){
                    this.priority_selected.splice(all_index,1);
                }else if(this.priority_selected.length == this.priority_obj.length-1){
                    this.priority_selected.push(this.Constants.SELECT_ALL);
                }
            }
            this.reset_infinite_state();
        },

        select_status(e){
            this.button_hide= true;
            this.card_select = false;
            if(e.target.value == this.Constants.SELECT_ALL && e.target.checked){
                this.status_selected = this.status_obj;
            }else if(e.target.value == this.Constants.SELECT_ALL && !e.target.checked){
                this.status_selected = [];
            }else if(this.status_selected.length != this.status_obj.length){
                let all_index = this.status_selected.findIndex(item => item == this.Constants.SELECT_ALL);
                if(all_index > -1){
                    this.status_selected.splice(all_index,1);
                }else if(this.status_selected.length == this.status_obj.length-1){
                    this.status_selected.push(this.Constants.SELECT_ALL);
                }
            }
            this.reset_infinite_state();
        },

        select_due_range(e){
            this.button_hide= true;
            this.card_select = false;
            this.dueDateRange.startDate =  moment(e.startDate).format("YYYY-MM-DD");
            this.dueDateRange.endDate =  moment(e.endDate).format("YYYY-MM-DD");
            if(this.dueDateRange.startDate == moment().add('-30','days').format("YYYY-MM-DD") &&  this.dueDateRange.endDate == moment().add('10','days').format("YYYY-MM-DD")){
                this.due_date_selected   = false;
            }else{
                this.due_date_selected   = true;
            }
            this.reset_infinite_state();
        },

        select_start_range(e){
            this.button_hide= true;
            this.card_select = false;
            this.startDateRange.startDate =  moment(e.startDate).format("YYYY-MM-DD");
            this.startDateRange.endDate =  moment(e.endDate).format("YYYY-MM-DD");
            if(this.startDateRange.startDate ==  moment().add('-30','days').format("YYYY-MM-DD") &&  this.startDateRange.endDate == moment().add('10','days').format("YYYY-MM-DD")){
                this.start_date_selected   = false;
            }else{
                this.start_date_selected   = true;
            }
            this.reset_infinite_state();
        },

        reset_infinite_state(){
            this.total_minutes_obj   = 0;
            this.not_exist_minute_data = false;
            this.reset_page = true;
            this.infiniteHandler();
        },


        filters_search(){
           this.reset_infinite_state();  
        },

        remove_search(){
            this.search_text = "";
            this.filters_search();
        }

    },

    
    filters:{
        regular_format(date,time_zone){
            return moment(date).utc().tz(time_zone).format("DD MMM, YYYY");
        },

        regular_format_without_zone(date){
            return moment(date).format("DD MMM, YYYY");
        },

        content_manage(content){
            content = content.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ').replace(/&nbsp;/,' ');

            if(content.length > 30){
                return content.substr(0, 30) + '...';
            }else{
                return content
            }
        },

        day_diff(date){
            return moment.duration(moment(date).diff(moment().startOf('day'))).asDays() > 0 ? (moment.duration(moment(date).diff(moment().startOf('day'))).asDays() + 1) : 0
        },

        min_type_abr(min_type){
            return min_type.substr(0,1);
        },
    }
}