<template>
   <div class="loader_popover">     
        <div class="popover_content p-3" >
            <div class="loader loader3">
                <img  width="100px" height="100px" src="@/assets/images/icons/loader_icon.gif">
            
            </div>
        </div>
   </div>
</template>

<style scoped>
    .loader_popover{
        position: fixed;
        z-index: 2000;
        top:0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(255,255,255,0.8);
    }

    .popover_content{
        position: fixed;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

</style>

<script>
export default {
    name: 'loader_popover',
    data(){
        return {
        }
    },
}
</script>